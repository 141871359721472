import {
  getExtraHours,
  isoDateToAmericanDate,
  isoDateToDate,
  isoDateToTimeDisplay,
  mergeDateDateAndTimeDate
} from 'aplication/utils/app/dateTimeCare/dateTimeCare'
import { objectIsEmpty } from 'aplication/utils/app/testValues'
import { TBooking } from 'domain/entities/TBooking'
import { useEffect, useState } from 'react'
import { useBookingDataContext } from 'view/contexts/bookingDataContext'

export type TUseBookingDaily = {
  daily: number | null
  extraHour: number
  extraHourValue: number
  dailyValue: number | null
  dailyTotal: number | null
  locationTax: number
}

/**
 * Calcula o total de horas extras a partir de um TBookingData(dados de reserva)
 */
export function calcExtraHours(bookingData: TBooking): number {
  const pickupDate = isoDateToDate(bookingData.pickup?.date)
  const pickupHour = isoDateToDate(bookingData.pickup?.hour)
  const returnDate = isoDateToDate(bookingData.return?.date)
  const returnHour = isoDateToDate(bookingData.return?.hour)

  if (!pickupDate || !returnDate || !pickupHour || !returnHour) {
    return 0
  }

  const pickupValue = mergeDateDateAndTimeDate(pickupDate, pickupHour)
  const returnValue = mergeDateDateAndTimeDate(returnDate, returnHour)

  return getExtraHours(pickupValue, returnValue)
}

/**
 * Calcula o valor das horas extras
 */
export function calcExtraHoursValue(
  extraHour: number | null,
  daylyValue: number | null
): number {
  if (!daylyValue || !extraHour) return 0
  if (!extraHour) return 0
  if (extraHour >= 6) return daylyValue
  return (daylyValue / 5) * extraHour
}

export function useBookingDaily(fromApi?: boolean): TUseBookingDaily {
  const { bookingData } = useBookingDataContext()
  const [daily, setDaily] = useState<number | null>(null)
  const [extraHour, setExtraHour] = useState<number>(0)
  const [extraHourValue, setExtraHourValue] = useState<number>(0)
  const [dailyValue, setDailyValue] = useState<number | null>(null)
  const [dailyTotal, setDailyTotal] = useState<number | null>(null)
  const [locationTax, setLocationTax] = useState<number>(0)

  /**
   * Calcula total de dias
   */
  function calcDaily(): number | null {
    const pickupDate = isoDateToAmericanDate(bookingData.pickup?.date, '/')
    const returnDate = isoDateToAmericanDate(bookingData.return?.date, '/')
    const pickupHour = isoDateToTimeDisplay(bookingData.pickup?.hour)
    const returnHour = isoDateToTimeDisplay(bookingData.return?.hour)

    const startDate = new Date(`${pickupDate} ${pickupHour}`).getTime()
    const endDate = new Date(`${returnDate} ${returnHour}`).getTime()
    const extraHours = calcExtraHours(bookingData)

    if (!startDate || !endDate) return null

    const oneDay = 1000 * 60 * 60 * 24
    const difference = endDate - startDate
    let result = Math.round(difference / oneDay)
    if (extraHours >= 6) result += 1
    return result
  }

  function calcLocationTax() {
    const pickupTax = bookingData.pickup?.location?.tax || 0
    const returnTax = bookingData.return?.location?.tax || 0
    const totalTax = pickupTax + returnTax
    setLocationTax(totalTax)
  }

  function calcDailyTotal(): number | null {
    if (!daily || !dailyValue) return null
    const total = dailyValue * daily
    return parseFloat(total.toFixed(2))
  }

  function countExtraHours() {
    const value = calcExtraHours(bookingData)
    const payValue = calcExtraHoursValue(value, dailyValue)
    setExtraHour(value)
    setExtraHourValue(payValue)
  }

  useEffect(() => {
    if (!bookingData || objectIsEmpty(bookingData)) return
    if (bookingData.dailyValue) {
      setDailyValue(bookingData.dailyValue)
    }

    const dailyCalc = fromApi ? bookingData.daily : calcDaily()

    if (dailyCalc === null || dailyCalc === undefined) {
      return
    } else if (dailyCalc > 0) {
      setDaily(dailyCalc)
    } else if (dailyCalc <= 0) {
      // permite reservas para devolucao no mesmo dia
      setDaily(1)
    }

    if (dailyCalc !== 0) {
      countExtraHours()
    }
    calcLocationTax()
  }, [bookingData])

  useEffect(() => {
    const dailyTotal = calcDailyTotal()
    if (dailyTotal) setDailyTotal(dailyTotal)
  }, [daily, dailyValue])

  return {
    daily,
    extraHour,
    extraHourValue,
    dailyValue,
    dailyTotal,
    locationTax
  }
}
